.header {
    display: flex;
    align-items: center;
    margin: 0 0 30px;
    font-size: 16px;
    font-weight: 600;
}

.id {
    text-align: left;
    margin: 0;
}
