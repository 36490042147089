.layout {
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.content {
    text-align: center;
    color: #fff;
    background-color: #fff;
    padding: 10px 20px;
    overflow-y: auto;
}

.header {
    width: auto;
    height: 64;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
}

.name {
    color: rgba(0, 0, 0, 0.88);
    margin-left: 12px;
    font-weight: 600;
    font-size: 15px;
}

.sider {
    text-align: center;
    line-height: 120px;
    color: #fff;
    padding: 0 5px;
}

.logo {
    line-height: 1;
    padding: 20px 0 30px;

    img {
        width: 60px;
        height: 60px;
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }
}
