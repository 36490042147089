.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    border: 1px solid #ffd13d;
    border-radius: 8px;
    padding: 20px 30px;
}
.form {
    width: 420px;
}
.inputNumber {
    width: 100%;
}
.send {
    color: #122c42;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
        color: #ffd13d;
    }
}
.btns {
    text-align: center;
}
.btn {
    width: 260px;
    font-weight: 600;
}
