.form {
    background-color: #fafafa;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 6px;
}
.inputNumber {
    width: 200px;
}
.avatar {
    border-radius: 12px;
}
