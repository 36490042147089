.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
}
.logo {
    width: 220px;
}
.desc {
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
}
.form {
    width: 280px;
    padding-bottom: 100px;
}
.link {
    text-decoration: underline;
    cursor: pointer;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    padding: 0 10px;
    margin-bottom: 24px;
}
.loginBtn {
    width: 280px;
    font-weight: 600;
}
